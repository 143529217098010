:root {
  --chat--color-primary: rgb(205, 49, 22) !important;
  --chat--color-primary-shade-50: rgb(205, 49, 22) !important;
  --chat--color-primary-shade-100: rgb(205, 49, 22) !important;
  --chat--color-secondary: rgb(22, 54, 55) !important;
  --chat--color-secondary-shade-50: rgb(229, 222, 210) !important;
  --chat--color-white: #fff;
  --chat--color-light: #f2f4f8;
  --chat--color-light-shade-50: #e6e9f1;
  --chat--color-light-shade-100: #c2c5cc;
  --chat--color-medium: #d2d4d9;
  --chat--color-dark: rgb(22, 54, 55) !important;
  --chat--color-disabled: #777980;
  --chat--color-typing: #404040;

  --chat--spacing: 1rem;
  --chat--border-radius: 0.5rem !important;
  --chat--transition-duration: 0.15s;

  --chat--window--width: 500px !important;
  --chat--window--height: 800px !important;

  --chat--header-height: auto;
  --chat--header--padding: var(--chat--spacing);
  --chat--header--background: var(--chat--color-dark);
  --chat--header--color: var(--chat--color-light);
  --chat--header--border-top: none;
  --chat--header--border-bottom: none;
  --chat--header--border-bottom: none;
  --chat--header--border-bottom: none;
  --chat--heading--font-size: 1.5em !important;
  --chat--header--color: var(--chat--color-light);
  --chat--subtitle--font-size: inherit;
  --chat--subtitle--line-height: 1 !important;

  --chat--textarea--height: 80px !important;

  --chat--message--font-size: .8rem !important;
  --chat--message--padding: var(--chat--spacing);
  --chat--message--border-radius: var(--chat--border-radius);
  --chat--message-line-height: 1.8;
  --chat--message--bot--background: var(--chat--color-white);
  --chat--message--bot--color: var(--chat--color-dark);
  --chat--message--bot--border: none;
  --chat--message--user--background: var(--chat--color-secondary);
  --chat--message--user--color: var(--chat--color-white);
  --chat--message--user--border: none;
  --chat--message--pre--background: rgba(0, 0, 0, 0.05);

  --chat--toggle--background: var(--chat--color-primary);
  --chat--toggle--hover--background: var(--chat--color-primary-shade-50);
  --chat--toggle--active--background: var(--chat--color-primary-shade-100);
  --chat--toggle--color: var(--chat--color-white);
  --chat--toggle--size: 64px;
}

/*@font-face {*/
/*  font-family: 'Bebas Neue Cyrillic';*/
/*  src: url('../public/fonts/bebasneuecyrillic.ttf');*/
/*}*/

/*@font-face {*/
/*  font-family: 'Bebas Neue Pro';*/
/*  src: url('../public/fonts/BebasNeuePro-Bold.ttf');*/
/*}*/


@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('../public/fonts/bebasneuecyrillic.ttf');
  font-style: Regular;
}

@font-face {
  font-family: 'Bebas Neue Cyrillic';
  src: url('../public/fonts/BebasNeuePro-SemiExpBold.eot');
  src: local('Bebas Neue Pro SemiExpanded Bold'), local('BebasNeuePro-SemiExpBold'),
  url('../public/fonts/BebasNeuePro-SemiExpBold.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/BebasNeuePro-SemiExpBold.woff2') format('woff2'),
  url('../public/fonts/BebasNeuePro-SemiExpBold.woff') format('woff'),
  url('../public/fonts/BebasNeuePro-SemiExpBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('../public/fonts/BebasNeuePro-SemiExpRegular.eot');
  src: local('Bebas Neue Pro SemiExpanded Regular'), local('BebasNeuePro-SemiExpRegular'),
  url('../public/fonts/BebasNeuePro-SemiExpRegular.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/BebasNeuePro-SemiExpRegular.woff2') format('woff2'),
  url('../public/fonts/BebasNeuePro-SemiExpRegular.woff') format('woff'),
  url('../public/fonts/BebasNeuePro-SemiExpRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas';
  src: url('../public/fonts/BebasNeuePro-SemiExpRegular.eot');
  src: local('Bebas Neue Pro SemiExpanded Regular'), local('BebasNeuePro-SemiExpRegular'),
  url('../public/fonts/BebasNeuePro-SemiExpRegular.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/BebasNeuePro-SemiExpRegular.woff2') format('woff2'),
  url('../public/fonts/BebasNeuePro-SemiExpRegular.woff') format('woff'),
  url('../public/fonts/BebasNeuePro-SemiExpRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/*@font-face {*/
/*  font-family: 'Bebas Neue Pro';*/
/*  src: url('../public/fonts/BebasNeuePro-BoldItalic.eot');*/
/*  src: local('Bebas Neue Pro Bold Italic'), local('BebasNeuePro-BoldItalic'),*/
/*  url('../public/fonts/BebasNeuePro-BoldItalic.eot?#iefix') format('embedded-opentype'),*/
/*  url('../public/fonts/BebasNeuePro-BoldItalic.woff2') format('woff2'),*/
/*  url('../public/fonts/BebasNeuePro-BoldItalic.woff') format('woff'),*/
/*  url('../public/fonts/BebasNeuePro-BoldItalic.ttf') format('truetype');*/
/*  font-weight: bold;*/
/*  font-style: italic;*/
/*}*/



/*@font-face {*/
/*  font-family: 'Bebas Neue Pro';*/
/*  src: url('../public/fonts/BebasNeuePro-Light.eot');*/
/*  src: local('Bebas Neue Pro Light'), local('BebasNeuePro-Light'),*/
/*  url('../public/fonts/BebasNeuePro-Light.eot?#iefix') format('embedded-opentype'),*/
/*  url('../public/fonts/BebasNeuePro-Light.woff2') format('woff2'),*/
/*  url('../public/fonts/BebasNeuePro-Light.woff') format('woff'),*/
/*  url('../public/fonts/BebasNeuePro-Light.ttf') format('truetype');*/
/*  font-weight: 300;*/
/*  font-style: normal;*/
/*}*/


/*@font-face {*/
/*  font-family: 'Bebas Neue Pro';*/
/*  src: url('../public/fonts/BebasNeuePro-LightItalic.eot');*/
/*  src: local('Bebas Neue Pro Light Italic'), local('BebasNeuePro-LightItalic'),*/
/*  url('../public/fonts/BebasNeuePro-LightItalic.eot?#iefix') format('embedded-opentype'),*/
/*  url('../public/fonts/BebasNeuePro-LightItalic.woff2') format('woff2'),*/
/*  url('../public/fonts/BebasNeuePro-LightItalic.woff') format('woff'),*/
/*  url('../public/fonts/BebasNeuePro-LightItalic.ttf') format('truetype');*/
/*  font-weight: 300;*/
/*  font-style: italic;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Bebas Neue Pro';*/
/*  src: url('../public/fonts/BebasNeuePro-Thin.eot');*/
/*  src: local('Bebas Neue Pro Thin'), local('BebasNeuePro-Thin'),*/
/*  url('../public/fonts/BebasNeuePro-Thin.eot?#iefix') format('embedded-opentype'),*/
/*  url('../public/fonts/BebasNeuePro-Thin.woff2') format('woff2'),*/
/*  url('../public/fonts/BebasNeuePro-Thin.woff') format('woff'),*/
/*  url('../public/fonts/BebasNeuePro-Thin.ttf') format('truetype');*/
/*  font-weight: 100;*/
/*  font-style: normal;*/
/*}*/


/*@font-face {*/
/*  font-family: 'Bebas Neue Pro';*/
/*  src: url('../public/fonts/BebasNeuePro-ThinItalic.eot');*/
/*  src: local('Bebas Neue Pro Thin Italic'), local('BebasNeuePro-ThinItalic'),*/
/*  url('../public/fonts/BebasNeuePro-ThinItalic.eot?#iefix') format('embedded-opentype'),*/
/*  url('../public/fonts/BebasNeuePro-ThinItalic.woff2') format('woff2'),*/
/*  url('../public/fonts/BebasNeuePro-ThinItalic.woff') format('woff'),*/
/*  url('../public/fonts/BebasNeuePro-ThinItalic.ttf') format('truetype');*/
/*  font-weight: 100;*/
/*  font-style: italic;*/
/*}*/


/*@font-face {*/
/*  font-family: 'Bebas Neue Pro';*/
/*  src: url('../public/fonts/BebasNeuePro-Bold.eot');*/
/*  src: local('Bebas Neue Pro Bold'), local('BebasNeuePro-Bold'),*/
/*  url('../public/fonts/BebasNeuePro-Bold.eot?#iefix') format('embedded-opentype'),*/
/*  url('../public/fonts/BebasNeuePro-Bold.woff2') format('woff2'),*/
/*  url('../public/fonts/BebasNeuePro-Bold.woff') format('woff'),*/
/*  url('../public/fonts/BebasNeuePro-Bold.ttf') format('truetype');*/
/*  font-weight: bold;*/
/*  font-style: normal;*/
/*}*/


/*@font-face {*/
/*  font-family: 'Bebas Neue Pro';*/
/*  src: url('../public/fonts/BebasNeuePro-Italic.eot');*/
/*  src: local('Bebas Neue Pro Italic'), local('BebasNeuePro-Italic'),*/
/*  url('../public/fonts/BebasNeuePro-Italic.eot?#iefix') format('embedded-opentype'),*/
/*  url('../public/fonts/BebasNeuePro-Italic.woff2') format('woff2'),*/
/*  url('../public/fonts/BebasNeuePro-Italic.woff') format('woff'),*/
/*  url('../public/fonts/BebasNeuePro-Italic.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: italic;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Bebas Neue Pro';*/
/*  src: url('../public/fonts/BebasNeuePro-Regular.eot');*/
/*  src: local('Bebas Neue Pro Regular'), local('BebasNeuePro-Regular'),*/
/*  url('../public/fonts/BebasNeuePro-Regular.eot?#iefix') format('embedded-opentype'),*/
/*  url('../public/fonts/BebasNeuePro-Regular.woff2') format('woff2'),*/
/*  url('../public/fonts/BebasNeuePro-Regular.woff') format('woff'),*/
/*  url('../public/fonts/BebasNeuePro-Regular.ttf') format('truetype');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

.App {
    font-family: librefranklin-medium;
    text-align: center;
  }
  
  body,
  html {
    margin: 0;
    width: 100%;
    /* height: 100%; */
    background-color: rgb(229, 222, 210);
  }
  /*#container {*/
  /*  width: 400px;*/
  /*  height: 400px;*/
  /*  overflow: hidden;*/
  /*  background-color: #fff;*/
  /*  position: fixed;*/
  /*  left: 0;*/
  /*  right: 0;*/
  /*  top: 0;*/
  /*  bottom: 0;*/
  /*  margin: auto;*/
  /*}*/
  /*div {*/
  /*  font-size: 0px;*/
  /*}*/

  /*.container-form {*/
  /*  margin: 10px;*/
  /*}*/

  .powerbi-scene {
    cursor: pointer;
  }

  .slide {
    margin: 0;
    padding: 0;
  }

#n8n-chat {
  height: 80vh;
}